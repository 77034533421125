<template>
  <el-form v-loading="loading" ref="dataForm" label-width="100px" class="demo-ruleForm" style="font-size: 14px!important;">
    <el-form-item label="稿子ID" style="margin-bottom: 0px">
      <span>{{dataForm.id}}</span>
    </el-form-item>
    <el-form-item label="状态" style="margin-bottom: 10px">
      <span>{{dataForm.status}}</span>
    </el-form-item>
    <el-form-item label="板型配置" style="margin-bottom: 10px">
      <div style="height: fit-content; display: flex; flex-direction: row; width: 100%">
        <div v-for="(item, index) in dataForm.pattern" :key="index" style="display: flex; flex-direction: row; width: 25%">
          <span style="margin: auto 10px auto 20px; padding: 10px; white-space: nowrap">{{ getPatternTitle(index) }}:</span>
          <div style="border: 1px solid rgb(0, 153, 255); border-radius: 10px; padding: 10px; box-sizing: border-box; white-space: normal; height: 100%">{{ item }}</div>
        </div>
      </div>
    </el-form-item>
    <el-form-item label="角色信息" style="margin-bottom: 10px">
      <div v-for="(item, index) in dataForm.roles" :key="index" style="border: 1px solid rgb(0, 153, 255); border-radius: 10px; position: relative; padding: 10px 10px; line-height: 20px!important; margin: 20px 0" >
<!--        <legend style="color: rgb(0, 153, 255); border-color: rgb(0, 153, 255)">{{item.name}}</legend>-->
        <div style="background-color: #ffffff; top: -15px; position: absolute; left: 10px; height: 30px; display: flex; flex-direction: column; align-content: center; padding: 0 5px">{{ item.name }}</div>
        <el-row style="padding: 5px 10px;">
          <el-col :span="3" :xs="4" style="text-align: right; padding: 5px 0; white-space: nowrap">角色名称：</el-col>
          <el-col :span="22" :xs="4" style="border: 1px solid rgb(0, 153, 255); border-radius: 10px; padding: 5px; white-space: normal; width: 200px">{{ item.name }}</el-col>
        </el-row>
        <el-row style="padding: 5px 10px;">
          <el-col :span="3" :xs="4" style="text-align: right; padding: 5px 0; white-space: nowrap">阵营：</el-col>
          <el-col :span="10" :xs="4" style="border: 1px solid rgb(0, 153, 255); border-radius: 10px; padding: 5px; white-space: normal; width: 200px">
            {{ getTeamName(item.team) }}
          </el-col>
        </el-row>
        <el-row style="padding: 5px 10px;">
          <el-col :span="3" :xs="4" style="text-align: right; padding: 5px 0; white-space: nowrap">技能详情描述：</el-col>
          <el-col :span="20" :xs="4" style="border: 1px solid rgb(0, 153, 255); border-radius: 10px; padding: 5px; white-space: normal; min-width: 300px">{{ item.desc }}</el-col>
        </el-row>
        <el-row style="padding: 5px 10px;">
          <el-col :span="3" :xs="4" style="text-align: right; padding: 5px 0; white-space: nowrap">角色设计思路：</el-col>
          <el-col :span="20" :xs="4" style="border: 1px solid rgb(0, 153, 255); border-radius: 10px; padding: 5px; white-space: normal; min-width: 300px">{{ item.design }}</el-col>
        </el-row>
        <el-row style="padding: 5px 10px;">
          <el-col :span="3" :xs="4" style="text-align: right; padding: 5px 0; white-space: nowrap">胜利条件：</el-col>
          <el-col :span="20" :xs="4" style="border: 1px solid rgb(0, 153, 255); border-radius: 10px; padding: 5px; white-space: normal; min-width: 300px">{{ item.win_rule }}</el-col>
        </el-row>
      </div>
    </el-form-item>
    <el-form-item label="Q&A" style="margin-bottom: 10px">
      <el-table
          :data="dataForm.q_a"
          style="margin-top:20px"
          :header-cell-style="{background:'#409EFF'}"
          border
      >
        <el-table-column prop="q" label="问题"> </el-table-column>
        <el-table-column prop="a" label="答案"> </el-table-column>
      </el-table>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "submission_detail",
  props: {
    data_params: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      dataForm: {
        id: '',
        status: '',
        pattern: [],
        roles: [],
        q_a: []
      }
    }
  },
  watch:{
    "data_params":{
      handler:function(val){
        this.setFormData();
      },
      immediate:true
    }
  },
  async mounted() {
    await this.setFormData();
  },
  methods: {
    getTeamName(team) {
      switch (team) {
        case 1:
          return "神职";
        case 2:
          return "狼人";
        case 3:
          return "村民";
        case 4:
          return "第三方";
        default:
          return "";
      }
    },
    async setFormData() {
      if(!this.data_params || !this.data_params._id || !this.data_params._id.length || !this.data_params.act_id || !this.data_params.act_id.length) {
        return;
      }

      this.loading = true;
      this.dataForm = {
        id: '',
        status: '',
        pattern: [],
        roles: [],
        q_a: []
      };

      let { data, errorCode } = await this.$http.detailSubmission({
        _id: this.data_params._id,
        act_id: this.data_params.act_id
      });

      let submission_data = data;

      if (submission_data && submission_data._id) {
        Object.keys(this.dataForm).forEach(key => {
          if(submission_data[key]) {
            this.dataForm[key] = submission_data[key];
          }
        });

        switch (submission_data.status) {
          case 0:
            this.dataForm.status = "待审核";
            break;
          case 1:
            this.dataForm.status = "已通过";
            break;
          case 2:
            this.dataForm.status = "已拒绝";
            break;
          default:
            this.dataForm.status = "";
            break;
        }

        // this.dataForm.roles = [];
        // this.dataForm.q_a = [];

        // if (submission_data.content_url && submission_data.content_url.length) {
        //   let dataDetail = await new Promise((resolve, reject) => {
        //     fetch(submission_data.content_url + "?r=" + Math.random())
        //         .then(res => res.text())
        //         .then(d => {
        //           console.log(d);
        //           resolve(JSON.parse(d));
        //         })
        //         .catch(function (err) {
        //           console.error(err);
        //           this.$message({
        //             message: '服务器异常，请联系管理员！',
        //             type: 'warning'
        //           });
        //
        //           resolve(false);
        //         });
        //   });
        //
        //   if (dataDetail) {
        //     this.dataForm.roles = dataDetail.roles;
        //     this.dataForm.q_a = dataDetail.q_a;
        //   }
        // }
      }

      this.loading = false;
    },
    getPatternTitle(index) {
      switch (index) {
        case 0:
          return "神职";
        case 1:
          return "狼人";
        case 2:
          return "村民";
        case 3:
          return "第三方";
        default:
          return "";
      }
    }
  }
}
</script>

<style scoped>

</style>
